<template>
    <KCLoadingIndicator
        v-if="loading"
        loading
    />
    <div
        v-else
        data-cy="claimsCard"
    >
        <BTable
            class="claims-table"
            data-cy="claimsTable"
            borderless
            :fields="fields"
            :items="currentClaims"
            :sort-desc.sync="sortDesc"
            :sort-by.sync="sortBy"
            show-empty
        >
            <template #head()="{ label, field: { key, sortable }}">
                <template v-if="sortable">
                    {{ label }}

                    <FontAwesomeIcon
                        v-if="sortBy !== key"
                        icon="sort"
                    />
                    <FontAwesomeIcon
                        v-else-if="sortDesc"
                        icon="sort-up"
                    />
                    <FontAwesomeIcon
                        v-else
                        icon="sort-down"
                    />
                </template>
            </template>

            <template #cell(claim)="row">
                <a
                    :href="claimsUrl(row.item.id)"
                    target="_blank"
                >{{ row.item.claim }}</a>
            </template>
            <template #cell(lossDate)="{ item }">
                {{ claimsDate(item.lossDate) }}
            </template>
            <template #cell(paid)="{ item }">
                {{ claimsCurrency(item.paid) }}
            </template>

            <template #empty>
                <div
                    v-if="errorOccurred"
                    class="error d-flex align-items-center"
                >
                    <FontAwesomeIcon
                        class="error-icon"
                        :icon="['fas', 'times-circle']"
                        size="2xl"
                    />

                    <p>
                        There was an error loading claims for this policy.<br>
                        Please contact technical support to report this issue (<a href="mailto:agntsupt@imtins.com">agntsupt@imtins.com</a>)
                    </p>
                </div>
                <p
                    v-else
                    class="text-center"
                >
                    No claims exist for this policy.
                </p>
            </template>
        </BTable>
        <hr>
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core';
    import {
        faSort,
        faSortUp,
        faSortDown,
        faTimesCircle
    } from '@fortawesome/pro-solid-svg-icons';
    import KCLoadingIndicator from '@imt/vue-kit-car/src/components/KCLoadingIndicator.vue';
    import {formatCurrency} from '@imt/vue-kit-car/src/mixins/inputFilters';
    import toastMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import dayjs from 'dayjs';
    import {mapActions} from 'vuex';

    library.add(faSort, faSortUp, faSortDown, faTimesCircle);

    export default {
        name: 'PDCardClaims',
        components: {
            KCLoadingIndicator,
        },
        mixins: [
            toastMixin
        ],
        props: {
            policy: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                claims: [],
                errorOccurred: false,
                loading: false,
                sortDesc: false,
                sortBy: null,
            };
        },
        computed: {
            fields() {
                return [
                    {
                        key: 'claim',
                        label: 'Claim',
                        sortable: true
                    },
                    {
                        key: 'lossDate',
                        label: 'Loss Date',
                        sortable: true,
                        thStyle: { width: "8rem" },
                    },
                    {
                        key: 'paid',
                        label: 'Paid',
                        sortable: true,
                    },
                    {
                        key: 'reserve',
                        label: 'Reserve',
                    },
                    {
                        key: 'description',
                        label: 'Description',
                    },
                ];
            },
            currentClaims() {
                if (!this.claims.length) {
                    return [];
                }

                return this.claims.map(
                    transaction => {
                        return {
                            id: transaction.claimId,
                            claim: transaction.claimNumber,
                            lossDate: new Date(transaction.dateOfLoss),
                            paid: Number(transaction.paidAmount.split(',').join('')),
                            reserve: this.claimsCurrency(transaction.reserveAmount),
                            description: this.claimsDescription(transaction.description)
                        };
                    }
                );
            },
        },
        async mounted() {
            await this.init();
        },
        methods: {
            claimsDate(date) {
                return dayjs(date).format('MM/DD/YYYY');
            },
            claimsDescription(description) {
                let snippet = description.slice(0, 125);

                return description !== snippet ? snippet + '...' : snippet;
            },
            claimsCurrency(currency) {
                return formatCurrency(currency, { style: 'currency', currency: 'USD' });
            },
            claimsUrl(id) {
                return `${process.env.VUE_APP_LEGACY_URL}/imtonline/Claims/Claims_Engine.pl?load=Claims_Info&claimid=${id}`;
            },
            async init() {
                this.loading = true;
                try {
                    this.claims = await this.fetchClaims({id: this.policy.id});
                } catch (error) {
                    if (error.response.status === 404) {
                        this.claims = [];
                    } else {
                        this.errorOccurred = true;
                        this.error('There was an issue retrieving claims data.');
                        utils.console.log(error);
                    }
                } finally {
                    this.loading = false;
                }
            },
            ...mapActions('policy', [
                'fetchClaims',
            ]),
        }
    };
</script>

<style
    lang="sass"
>
  .error
      padding: 0 2rem 0 2rem

      p
          margin: 0

      .error-icon
          color: var(--kc-red-600)
          margin-right: 1rem

          button
              color: var(--kc-gray-50)
              opacity: 1

  .claims-table
      max-height: 37.5rem
</style>

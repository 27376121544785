<template>
    <div
        v-if="hasFilteredAmendment"
        id="amendmentCard"
    >
        <BTable
            data-cy="amendments"
            borderless
            :fields="fields"
            :items="filteredAmendment"
        >
            <template #cell(processingStatus)="{ item }">
                <a :href="policyUrl(item)">
                    {{ formatField(item['processingStatus']) }}
                </a>
            </template>
            <template #cell(createdBy)="{ item }">
                {{ users[item.createdBy] ? users[item.createdBy].username : 'Loading...' }}
            </template>
            <template #cell(delete)="{ item }">
                <FontAwesomeIcon
                    v-if="item.processingStatus === 'in_progress'"
                    fas
                    icon="trash"
                />
            </template>
        </BTable>
        <div class="row border-top">
            <span class="col-3 p-3">
                <b>Actions</b>
            </span>
        </div>
        <div class="row col-3">
            <KCDropdown
                id="defaultDropdown"
                :options="filteredActions"
            />
        </div>
    </div>
</template>

<script>
    import KCDropdown from '@imt/vue-kit-car/src/components/Dropdown.vue';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import dayjs from 'dayjs';
    import {mapActions, mapState} from 'vuex';

    export default {
        name: 'PDCardAmendment',
        components: {
            KCDropdown,
        },
        mixins: [
            toastsMixin
        ],
        props: {
            policy: {
                type: Object,
                required: true
            },
            // Actions to display in the dropdown
            actions: {
                type: Array,
                required: false,
                default: () => []
            },
        },
        data() {
            return {
                users: {},
            };
        },
        computed: {
            filteredAmendment() {
                return this.policy.versions.filter(transaction =>
                    transaction.transactionType === 'amendment' && ['on_hold', 'in_progress'].includes(transaction.processingStatus)
                ).map(
                    transaction => {
                        return {
                            processingStatus: transaction.processingStatus,
                            premium: this.formattedBalance(1000),
                            transactionEffectiveDate: dayjs(transaction.transactionEffectiveDate).format('MM/DD/YYYY'),
                            termExpirationDate: dayjs(transaction.transactionEffectiveDate).format('MM/DD/YYYY'),
                            company: this.company.name,
                            createdBy: transaction.createdBy.id,
                            policyId: transaction.policyId,
                            policySystemId: transaction.policySystemId,
                            versionId: transaction.id
                        };
                    }
                );
            },
            hasFilteredAmendment() {
                return this.filteredAmendment.length > 0;
            },
            filteredActions() {
                return this.actions.filter(action => !('disabled' in action) || !action.disabled);
            },
            fields() {
                return [
                    {
                        key: 'processingStatus',
                        label: 'Status'
                    },
                    {
                        key: 'premium',
                        label: 'Premium'
                    },
                    {
                        key: 'transactionEffectiveDate',
                        label: 'Trans. Date'
                    },
                    {
                        key: 'termExpirationDate',
                        label: 'Exp. Date'
                    },
                    {
                        key: 'company',
                        label: 'Company'
                    },
                    {
                        key: 'createdBy',
                        label: 'Started by'
                    },
                    {
                        key: 'delete',
                        label: 'Delete'
                    }
                ];
            },
            ...mapState('toolbox', [
                'company'
            ]),
        },
        async mounted() {
            if (!this.hasFilteredAmendment) {
                this.$emit('remove', 'amendment');
            } else {
                for (let transaction of this.filteredAmendment) {
                    await this.getUser(transaction.createdBy);
                }
            }
        },
        methods: {
            ...mapActions('policy', [
                'fetchUserById'
            ]),
            formatField(fieldValue) {
                return fieldValue
                    .replace(/_/g, ' ')
                    .split(' ')
                    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                    .join(' ');
            },
            policyUrl(policy) {
                return `${process.env.VUE_APP_ADMIN_URL_CMS}/${policy.policySystemId}?policyId=${policy.policyId}&versionId=${policy.id}`;
            },
            formattedBalance(balance) {
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(balance);
            },
            async getUser(userId) {
                try {
                    const userData = await this.fetchUserById(userId);
                    this.$set(this.users, userId, userData);
                } catch (error) {
                    utils.console.log(error);
                    this.error('There was an error fetching user data.', 'Amendment Error');
                }
            },
        }
    };
</script>

<style
    lang="sass"
>
    .table.b-table th
        border-bottom: 0!important
    .table.b-table td
        border-top: 0!important
</style>

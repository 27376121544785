<template>
    <div class="container align-content-stretch d-flex flex-column">
        <KCLoadingIndicator
            v-if="loading"
            :loading="loading"
        />
        <KCNotFound
            v-else-if="notFound"
            data-cy="notFound"
        />
        <div
            v-else
            class="d-flex flex-column min-h-screen align-items-stretch"
            style="min-height: 100vh;"
        >
            <KCHeader
                elevated
                variant="policy-system"
                :user="user"
            >
                <h1
                    class="header-title"
                    data-cy="headerTitle"
                >
                    {{ pageData.policy.number ? pageData.policy.number : '-' }}
                </h1>
            </KCHeader>
            <div class="d-flex min-h-full flex-grow-1">
                <PDSideNavigation
                    :policy="policy"
                    :latest-policy-version="pageData.latestPolicyVersion"
                />
                <main class="main-container container-fluid">
                    <span v-if="pageData.policy">
                        <PDInfoCards
                            :policy="pageData.policy"
                        />
                    </span>
                    <RouterView />
                </main>

                <KCMenu
                    v-if="pageData.latestPolicyVersion"
                    data-cy="infoPanelMenu"
                >
                    <template #header>
                        <dl data-cy="infoPanelList">
                            <div data-cy="namedInsuredGroup">
                                <dt data-cy="namedInsuredLabel">
                                    Named Insured
                                </dt>
                                <dd data-cy="namedInsuredValue">
                                    -
                                </dd>
                            </div>
                            <div data-cy="mailingAddressGroup">
                                <dt data-cy="mailingAddressLabel">
                                    Mailing Address
                                </dt>
                                <dd data-cy="mailingAddressValue">
                                    -
                                </dd>
                            </div>
                            <div data-cy="policyStateGroup">
                                <dt data-cy="policyStateLabel">
                                    Policy State
                                </dt>
                                <dd data-cy="policyStateValue">
                                    {{ pageData.latestPolicyVersion.state ? pageData.latestPolicyVersion.state.toUpperCase() : '-' }}
                                </dd>
                            </div>
                            <div data-cy="customerIdGroup">
                                <dt data-cy="customerIdLabel">
                                    Customer ID
                                </dt>
                                <dd data-cy="customerIdValue">
                                    123456
                                </dd>
                            </div>
                            <div data-cy="agentGroup">
                                <dt data-cy="agentLabel">
                                    Agent
                                </dt>
                                <dd data-cy="agentValue">
                                    654321-001
                                </dd>
                            </div>
                            <div data-cy="statusGroup">
                                <dt data-cy="statusLabel">
                                    Status
                                </dt>
                                <dd data-cy="statusValue">
                                    {{ pageData.latestPolicyVersion.processingStatus ? startCase(camelCase(pageData.latestPolicyVersion.processingStatus)) : '-' }}
                                </dd>
                            </div>
                            <div data-cy="originalEffectiveDateGroup">
                                <dt data-cy="originalEffectiveDateLabel">
                                    Original Effective Date
                                </dt>
                                <dd data-cy="originalEffectiveDateValue">
                                    {{ pageData.latestPolicyVersion.originalEffectiveDate ? formatted(pageData.latestPolicyVersion.originalEffectiveDate, 'MM/DD/YYYY') : '-' }}
                                </dd>
                            </div>
                            <div v-if="isInternal">
                                <KCButton
                                    data-cy="pushClientBtn"
                                    variant="internal-only"
                                    :disabled="clientLoading"
                                    @click="pushToClient"
                                >
                                    <FontAwesomeIcon
                                        v-if="clientLoading"
                                        class="fa-spin"
                                        :icon="'spinner'"
                                    />
                                    <span
                                        v-else
                                        data-cy="pushClientWording"
                                    >
                                        Push to Client
                                    </span>
                                </KCButton>
                            </div>
                        </dl>
                    </template>
                </KCMenu>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        KCButton,
        KCHeader,
        KCLoadingIndicator,
        KCMenu,
        KCNotFound,
    } from '@imt/vue-kit-car/';
    import authMixin from '@imt/vue-toolbox/src/mixins/auth';
    import toastsMixin from '@imt/vue-toolbox/src/mixins/toasts';
    import utils from '@imt/vue-toolbox/src/utils';
    import { formatted } from '@imt/vue-toolbox/src/utils/date';
    import camelCase from 'lodash/camelCase';
    import startCase from 'lodash/startCase';
    import { mapActions, mapState } from 'vuex';

    import PDInfoCards from '@/components/PDInfoCards.vue';
    import PDSideNavigation from '@/components/PDSideNavigation.vue';


    export default {
        name: 'PDBase',
        components: {
            KCButton,
            KCHeader,
            KCLoadingIndicator,
            KCMenu,
            KCNotFound,
            PDSideNavigation,
            PDInfoCards,
        },
        mixins: [
            authMixin,
            toastsMixin
        ],
        data() {
            return {
                pageData: {
                    policy: {},
                    latestPolicyVersion: {},
                },
                clientLoading: false,
                forceReload: false,
                loading: true,
                notFound: false,
            };
        },
        computed: {
            isInternal() {
                return this.userCan('policy_system_internal');
            },
            ...mapState('policy', [
                'policy',
                'latestPolicyVersion',
            ]),
        },
        async created() {
            await this.initialize();
            this.loading = false;
        },
        mounted() {
            document.title = this.$route.params.number;
        },
        methods: {
            async initialize() {
                if (this.$route.params.number) {
                    await this.fetchPageInfo({number: this.$route.params.number, policyQuery: 'include=versions'});

                    if (!this.policy) {
                        this.notFound = true;
                    } else {
                        await Promise.all([
                            await this.fetchPolicySystem(this.latestPolicyVersion.policySystemId),
                            await this.fetchCompany(this.latestPolicyVersion.companyId)
                        ]);
                    }

                    this.pageData.policy = this.policy;
                    this.pageData.latestPolicyVersion = this.latestPolicyVersion;
                } else {
                    await this.$router.push({ path: '*' });
                }
            },
            camelCase,
            formatted,
            async pushToClient() {
                this.clientLoading = true;
                try {
                    await this.pushPolicyToClient({id: this.policy.id});
                    this.success('Latest version has been manually updated on client!');
                } catch (error) {
                    utils.console.log(error);
                    this.error('There was an error updating client.');
                } finally {
                    this.clientLoading = false;
                }
            },
            startCase,
            ...mapActions('policy', [
                'fetchPageInfo',
                'pushPolicyToClient'
        ]),
            ...mapActions('toolbox', [
                'fetchCompany',
                'fetchPolicySystem'
            ])
        }
    };
</script>

<style lang="sass" scoped>
    .container
        max-width: 1640px
        min-height: 100vh

    .main-container
        background-color: var(--kc-blue-100)

    .min-h-full
        min-height: 100%

    .min-h-screen
        min-height: 100vh
</style>

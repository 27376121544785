<template>
    <div v-if="priorTerms.length">
        <BTable
            data-cy="priorTerms"
            borderless
            :fields="fields"
            :items="priorTerms"
        >
            <template #cell(links)="{ item }">
                <a :href="policyUrl(item)">
                    <span v-if="item.processingStatus === 'processed'">
                        View Original Quote
                    </span>
                    <span v-else>
                        View Term
                    </span>
                </a>
            </template>
        </BTable>
    </div>
</template>

<script>
    import dayjs from 'dayjs';

    export default {
        name: 'PDCardPriorTerms',
        props: {
            policy: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                collapse: false,
            };
        },
        computed: {
            collapseAll() {
                return this.$store.state.policy.collapseAllControl;
            },
            fields() {
                return [
                    {
                        key: 'termEffectiveDate',
                        label: 'Eff. Date',
                    },
                    {
                        key: 'termExpirationDate',
                        label: 'Exp. Date',
                    },
                    // Todo: When WTW is integrated we will need to format this once dynamic
                    {
                        key: 'premium',
                        label: 'Premium',
                    },
                    {
                        key: 'links',
                        label: 'Links',
                    },
                ];
            },
            priorTerms() {
                return this.policy.versions.filter((v) => ['processed', 'rejected'].includes(v.processingStatus)).map(
                    transaction => {
                        return {
                            processingStatus: transaction.processingStatus,
                            premium: this.formattedBalance(1000),
                            termEffectiveDate: dayjs(transaction.transactionEffectiveDate).format('MM/DD/YYYY'),
                            termExpirationDate: dayjs(transaction.termExpirationDate).format('MM/DD/YYYY'),
                        };
                    }
                );
            },
        },
        mounted() {
            if (this.priorTerms.length <= 0) {
                this.$emit('remove', 'priorTerms');
            }
        },
        methods: {
            policyUrl(policy) {
                return `${process.env.VUE_APP_ADMIN_URL_CMS}/${policy.policySystemId}?policyId=${policy.policyId}&versionId=${policy.id}`;
            },
            formattedBalance(balance) {
                return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(balance);
            },
        }
    };
</script>

<style
    lang="sass"
>
    .table.b-table th
        border-bottom: 0!important
    .table.b-table td
        border-top: 0!important
</style>

<template>
    <div
        v-if="hasCurrentTermTransactions"
    >
        <BTable
            data-cy="currentTerms"
            :items="currentTermTransactions"
            :fields="fields"
        >
            <template #cell(processingStatus)="row">
                <a :href="row.item.statusLink">{{ row.item.processingStatus }}</a>
            </template>
            <template
                #cell(details)="row"
            >
                <FontAwesomeIcon
                    :icon="['fas', 'chevron-down']"
                    @click="toggleDetails(row.item)"
                />
            </template>
        </BTable>
        <BCollapse
            v-model="showDetails"
            class="bg-secondary p-5"
        >
            <slot name="content" />
        </BCollapse>
        <div
            id="dropdown-wrapper"
            class="top-border rounded bg-secondary-100 border-gray-300 pt-3"
        >
            <label
                for="actionsDropdown"
                class="col-3 p-0"
            >
                <b>Actions</b>
            </label>
            <KCDropdown
                id="actionsDropdown"
                class="col-3 p-0"
                placeholder="Select"
            />
        </div>
    </div>
</template>

<script>
    import {library} from '@fortawesome/fontawesome-svg-core';
    import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
    import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
    import KCDropdown from '@imt/vue-kit-car/src/components/Dropdown.vue';
    import dayjs from 'dayjs';
    import {startCase} from 'lodash';
    import {mapState} from 'vuex';

    library.add(faChevronDown);

    const defaultStatuses = ['in_progress', 'submitted', 'on_hold', 'processed', 'rejected', 'temporary'];
    export default {
        name: 'PDCardCurrentTerm',
        components: {
            KCDropdown,
            FontAwesomeIcon
        },
        props: {
            policy: {
                type: Object,
                required: true
            },
            policyUrl: {
                type: String,
                required: true,
            },
            statusesToDisplay: {
                type: Array,
                required: false,
                default: () => defaultStatuses
            }
        },
        data() {
            return {
                fields: ['processingStatus', 'premium', 'eff. Date', 'exp. Date', 'company', 'details'],
                showDetails: false,
                details: []
            };
        },
        computed: {
            currentTermTransactions() {
                if (!this.policy.versions?.length) {
                    return [];
                }

                return this.policy.versions.filter(transaction =>
                    this.statusesToDisplay.includes(transaction.processingStatus)
                ).map((transaction) => {
                    return {
                        processingStatus: startCase(this.getStatusToDisplay(transaction)),
                        statusLink: `${this.policyUrl}?policyId=${this.policy.id}&versionId=${transaction.id}`,
                        premium: 1000,
                        ['eff. Date']: dayjs(transaction.termEffectiveDate).format('MM/DD/YYYY'),
                        ['exp. Date']: dayjs(transaction.termExpirationDate).format('MM/DD/YYYY'),
                        company: this.company.name,
                    };
                });
            },
            hasCurrentTermTransactions() {
                return this.currentTermTransactions.length > 0;
            },
            ...mapState('toolbox', [
                'company'
            ]),
        },
        async mounted() {
            if (!this.hasCurrentTermTransactions) {
                this.$emit('remove', 'activeTerm');
            }
        },
        methods: {
            toggleDetails() {
                // TODO make this show status details
                this.showDetails = !this.showDetails;
            },
            getStatusToDisplay(transaction) {
                return transaction.processingStatus;
            }
        }
    };
</script>

<style
    lang="sass"
>
  .top-border
    border-top: 2px solid rgb(209, 209, 209, 0.6)
</style>
